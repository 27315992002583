import firebase from "firebase/app"
import 'firebase/messaging';
import { showNotificationToast } from './ToastHelper'
import BrowserHelper from "./BrowserHelper";
import UserDeviceApi from '/network/UserDevice'
import packageData from '/package.json'
import { FIREBASE_CONFIG } from '/global.config'

const startFirebaseTracking = async () => {
  if (firebase.apps.length) { return }

  let messaging
  try {
    firebase.initializeApp(FIREBASE_CONFIG)

    messaging = firebase.messaging()
  } catch (error) {
    console.warn('[FCM] Error:', error)
  }

  if (messaging) {
    if (typeof window !== 'undefined' && typeof window !== undefined) {
      try {
        const token = await messaging.getToken({
          vapidKey: 'BGuarfXVWXiFz2Rhh6I2lB_ZkYNPainC0RGMHzyC2DmUZQsuPkYSKOhJ7qW_KcyLcs2F5_3defCYVflwjbsgwzk',
        })

        UserDeviceApi.create({
          imei: BrowserHelper.imei,
          brand: BrowserHelper.brand,
          model: BrowserHelper.model,
          name: BrowserHelper.name,
          device_token: token,
          app_version: packageData.version,
          os_version: BrowserHelper.osVersion,
        })
      } catch (error) {
        console.log(error)
      }
    }

    messaging.onMessage(response => {
      console.log('<FCM> onMessage')
      if (response && response.notification) {
        const { notification } = response
        // eslint-disable-next-line no-alert
        showNotificationToast(`${notification.title}: ${notification.body}`)
      }
    })
  }
}

export { startFirebaseTracking }