import axios from 'axios';
import { SERVER_URL, CLIENT_ID } from '/global.config'

axios.defaults.headers.ClientID = CLIENT_ID

class Base {

  async get(url, params) {
    try {
      const apiResponse = await axios.get(`${SERVER_URL}/api/v1/${url}`, {
        params
      })
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  async post(url, params) {
    try {
      const apiResponse = await axios.post(`${SERVER_URL}/api/v1/${url}`, params)
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  async put(url, params) {
    try {
      const apiResponse = await axios.put(`${SERVER_URL}/api/v1/${url}`, params)
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  async delete(url, params) {
    try {
      const apiResponse = await axios.delete(`${SERVER_URL}/api/v1/${url}`, { params })
      return apiResponse
    } catch (error) {
      return { data: { success: false, error } }
    }
  }

  setAuthToken(token) {
    axios.defaults.headers.Authorization = token
  }

}

export default Base