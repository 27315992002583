import Head from 'next/head'
import PropTypes from 'prop-types'
import { FRONT_END_URL, TWITTER_USERNAME, S3_FOLDER_PATH, PROJECT_NAME, CLIENT_NAME, SEO_DESCRIPTION } from "/global.config"

const Meta = ({ title, description, route }) => {
  const concatenatedPageTitle = `${title ? `${title} | ${PROJECT_NAME}` : PROJECT_NAME} | ${CLIENT_NAME}`

  return (
    <Head>
      <title>{concatenatedPageTitle}</title>
      <meta content={description} name="description" />
      <meta content={concatenatedPageTitle} property="og:title" />
      <meta content={description} property="og:description" />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={`${S3_FOLDER_PATH}social-image.jpg`} />
      <meta property="og:url" content={`${FRONT_END_URL}${route}`} />

      <meta name="twitter:card" content="summary"></meta>
      <meta name="twitter:site" content={`@${TWITTER_USERNAME}`}></meta>
      <meta name="twitter:creator" content={`@${TWITTER_USERNAME}`}></meta>
      <meta content={concatenatedPageTitle} property="twitter:title" />
      <meta content={description} property="twitter:description"
      />
    </Head>
  )
}

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  route: PropTypes.string,
}

Meta.defaultProps = {
  title: null,
  description: SEO_DESCRIPTION || 'Home of raffles, auctions and much more...',
  route: '/',
}

export default Meta