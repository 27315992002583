import Base from './Base'

class Authentication extends Base {
  async discordSignin(code, redirectUri) {
    const data = await this.post('authentication/discord', {
      code,
      redirect_uri: redirectUri
    })
    return data?.data
  }
}

export default new Authentication()