import Base from './Base'

class UserDevice extends Base {
  async create(params) {
    const data = await this.post('user_devices', params)
    return data?.data
  }

  async deleteDevice(imei) {
    const data = await this.delete('user_devices', { imei })
    return data?.data
  }
}

export default new UserDevice()