import React, { createContext, useState, useContext, useEffect } from "react"
import Cookies from "js-cookie"
import { useRouter } from "next/router"
import { startFirebaseTracking } from "/helpers/FirebaseHelper"
import BrowserHelper from "/helpers/BrowserHelper"

import Meta from "/components/Meta"
import Spinner from "/components/Spinner"

//api here is an axios instance which has the baseURL set according to the env.
import UserApi from "/network/User"
import UserDeviceApi from "/network/UserDevice"
import AuthenticationApi from "/network/Authentication"

import * as Sentry from "@sentry/nextjs"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function loadUserFromCookies() {
      const token = Cookies.get("AUTH_TOKEN")
      if (token) {
        UserApi.setAuthToken(token)
        const user = await UserApi.info()
        if (user) {
          setUser(user)
        }
      }
      setLoading(false)
    }
    loadUserFromCookies()
  }, [])

  useEffect(() => {
    if (user) {
      Sentry.setUser({ id: user.id })
      startFirebaseTracking()
    } else {
      Sentry.setUser(null)
    }
  }, [user])

  const login = async (code, redirectUri) => {
    setLoading(true)

    const data = await AuthenticationApi.discordSignin(code, redirectUri)

    if (data?.auth_token) {
      Cookies.set("AUTH_TOKEN", data.auth_token, { expires: null })
      UserApi.setAuthToken(data.auth_token)
      setUser(data)
    } else {
      alert(data?.error?.response?.data?.message || 'There was an error logging you in')
      setUser(null)
    }

    setLoading(false)
  }

  const update = async (params) => {
    const data = await UserApi.update(params)
    setUser(data)
  }

  const logout = async () => {
    setLoading(true)

    await UserDeviceApi.deleteDevice(BrowserHelper.imei)

    Cookies.remove("AUTH_TOKEN")
    setUser(null)
    delete UserApi.setAuthToken(null)
    window.location.pathname = "/"

    // setLoading(false)
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!user, user, login, loading, logout, update }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
export const ProtectRoute = ({ children }) => {
  const { loading } = useAuth()
  const { pathname } = useRouter()

  if (pathname !== "/login") {
    if (loading) {
      return (
        <>
          <Meta />
          <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 font-mono bg-neutral-700 h-screen login-page">
            <div className="mx-auto justify-center p-5 bg-white rounded relative z-10">
              <Spinner />
            </div>
          </div>
        </>
      )
    }

    // else if (!isAuthenticated) {
    //   try {
    //     push("/login")
    //   } catch (error) {
    //     console.log("ERROR PUSHING TO LOGIN")
    //   }
    // }
  }

  return children
}
