import { useEffect, useMemo, useState } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  SolletWalletAdapter,
  SolletExtensionWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { ToastContainer } from "react-toastify";
import Head from "next/head";
import Script from "next/script";
import "@fortawesome/fontawesome-svg-core/styles.css"; // import Font Awesome CSS
import 'tippy.js/dist/tippy.css';
import { config } from "@fortawesome/fontawesome-svg-core";

import {
  SOLANA_NETWORK,
  S3_FOLDER_PATH,
  GTAG_ID,
  COLORS,
} from "/global.config";
import { AuthProvider, ProtectRoute } from "/contexts/auth";
import Header from "/components/Skeleton/Header";
import Footer from "/components/Skeleton/Footer";
import Maintenance from "/components/Maintenance";

import "react-toastify/dist/ReactToastify.css";
import "/styles/globals.css";
import "@solana/wallet-adapter-react-ui/styles.css";

config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

// TODO: delete this and tell the processor to include
const AVAILABLE_COLORS = ["green", "purple", "orange"];

function MyApp({ Component, pageProps }) {
  // return <Maintenance />

  const [actionCableConsumer, setActionCableConsumer] = useState(null);

  let network = null;
  switch (SOLANA_NETWORK) {
    case "dev":
      network = WalletAdapterNetwork.Devnet;
      break;
    case "test":
      network = WalletAdapterNetwork.Testnet;
      break;
    default:
      network = WalletAdapterNetwork.Mainnet;
      break;
  }

  // You can also provide a custom RPC endpoint.
  // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const endpoint = 'https://mainnet.helius-rpc.com/?api-key=d786306a-2798-45ab-86e3-04aa382fb0ec'

  // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
  // Only the wallets you configure here will be compiled into your application, and only the dependencies
  // of wallets that your users connect to will be loaded.
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new SolletWalletAdapter(),
      new SolletExtensionWalletAdapter(),
    ],
    [network]
  );

  // useEffect(() => {
  //   if (global?.window) {
  //     async function getConsumer() {
  //       const { createConsumer } = await import("@rails/actioncable");
  //       const consumer = createConsumer(`wss://api.thestonedfrogs.io/cable`);
  //       setActionCableConsumer(consumer);
  //     }
  //     // getConsumer();
  //   }
  //   return () => { };
  // }, [global?.window]);

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <div className="main-bg-color">
        <div className="main-container bg-illustration flex flex-col min-h-screen">
          <Header />
          <div className="container mx-auto pt-52 lg:pt-32">{page}</div>
          <Footer />
        </div>
      </div>
    ));

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=no"
        />

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-57x57.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-60x60.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-72x72.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-76x76.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-114x114.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-120x120.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-144x144.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-152x152.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${S3_FOLDER_PATH}favicon/apple-icon-180x180.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={`${S3_FOLDER_PATH}favicon/android-icon-192x192.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${S3_FOLDER_PATH}favicon/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href={`${S3_FOLDER_PATH}favicon/favicon-96x96.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${S3_FOLDER_PATH}favicon/favicon-16x16.png`}
        />
        <link rel="manifest" href={`${S3_FOLDER_PATH}favicon/manifest.json`} />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content={`${S3_FOLDER_PATH}favicon/ms-icon-144x144.png`}
        />
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <Script
        src="https://cdn.jsdelivr.net/npm/tw-elements/dist/js/index.min.js"
        strategy="lazyOnload"
      />
      {GTAG_ID && (
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
          strategy="afterInteractive"
        />
      )}
      {GTAG_ID && (
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || []
          function gtag(){window.dataLayer.push(arguments)}
          gtag('js', new Date())

          gtag('config', '${GTAG_ID}')
        `}
        </Script>
      )}
      {COLORS.dark && (
        <Script id="darkmode">
          {`
        if (
          localStorage.getItem('color-theme') === 'dark' ||
          (!('color-theme' in localStorage) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches)
        ) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      `}
        </Script>
      )}

      <AuthProvider>
        <ProtectRoute>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
              <WalletModalProvider>
                <style jsx global>{`
                    :root {
                      --color-primary: ${COLORS.light.primary};
                      --color-text-primary: ${COLORS.light.primary};
                      --color-secondary: ${COLORS.light.secondary};
                      --color-text-secondary: ${COLORS.light.secondary};
                    }
                    .main-bg-color {
                      background-color: ${COLORS.light.background};
                    }

                    .dark {
                      --color-primary: ${COLORS.dark?.primary};
                      --color-text-primary: ${COLORS.dark?.primary};
                      --color-secondary: ${COLORS.dark?.secondary};
                      --color-text-secondary: ${COLORS.dark?.secondary};
                    }
                    .dark .main-bg-color {
                      background-color: ${COLORS.dark?.background};
                    }
                    .bg-illustration {
                      background-image: url(${S3_FOLDER_PATH}background.jpg) !important;
                      background-size: cover;
                      background-position: center;
                      background-repeat: no-repeat;
                      height: 100vh;
                      overflow-y: auto;
                    }
                    .dark .bg-illustration {
                      background-image: url(${S3_FOLDER_PATH}background-dark.jpg) !important;
                      background-size: cover;
                      background-position: center;
                      background-repeat: no-repeat;
                      height: 100vh;
                      overflow-y: auto;
                    }
                    .login-page {
                      background-image: url(${S3_FOLDER_PATH}login-background.jpg);
                    }
                  `}</style>

                {getLayout(
                  <Component
                    {...pageProps}
                    actionCableConsumer={actionCableConsumer}
                  />
                )}
                <ToastContainer autoClose={2000} closeOnClick />
              </WalletModalProvider>
            </WalletProvider>
          </ConnectionProvider>
        </ProtectRoute>
      </AuthProvider>
    </>
  );
}

export default MyApp;
