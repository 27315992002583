import Image from "next/image";
import { S3_FOLDER_PATH, PROJECT_NAME } from "/global.config";
import Meta from '/components/Meta'


const Maintenance = () => {
  return (
    <>
      <Meta title='Maintenance' route='/maintenance' />

      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 font-mono bg-neutral-700 h-screen login-page">
        <div className="relative z-10">
          <div className="relative w-48 h-48 mx-auto">
            <Image
              width={300}
              height={300}
              src={`${S3_FOLDER_PATH}logo.png`}
              alt={PROJECT_NAME}
            />
          </div>

          <div className="flex justify-center text-xl pt-2 text-white mt-1 tracking-wider">
            {PROJECT_NAME} | Under maintenance
          </div>
        </div>
      </div>
    </>
  );
};

Maintenance.getLayout = function getLayout(page) {
  return page;
};

export default Maintenance;
