import { useAuth } from "/contexts/auth"
import ClientConfig from '/client.config.json'
import Spinner from "/components/Spinner"
import clsx from "clsx"

const LoginButton = ({ className }) => {
  const { loading } = useAuth()

  const redirectUri = () => {
    if (global?.window) {
      return `${window.location.origin}/login`
    }

    return "/login"
  }

  const redirectToLogin = () => {
    if (global?.window) {
      window.location = `https://discord.com/api/oauth2/authorize?client_id=${ClientConfig.discord_client_id}&redirect_uri=${redirectUri()}&response_type=code&scope=identify`
    }
  }

  return (
    <button
      disabled={loading}
      onClick={redirectToLogin}
      data-mdb-ripple="true"
      data-mdb-ripple-color="light"
      className={
        clsx(
          "flex justify-center py-2 px-4 rounded-md font-medium text-primary bg-secondary",
          className
        )
      }
    >
      {loading ? <Spinner /> : "Login with Discord"}
    </button>
  )
}

export default LoginButton
