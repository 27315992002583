import { toast } from "react-toastify";

const showNotificationToast = (message) => {
  toast.warning(message, { position: toast.POSITION.BOTTOM_RIGHT })
}

const showErrorToast = (message) => {
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT })
}

const showLoadingToast = ({ message }) => {
  const id = toast.info(message, {
    position: toast.POSITION.BOTTOM_RIGHT,
    isLoading: true,
    autoClose: false,
    closeOnClick: false,
    closeButton: false,
  });

  return id;
};

const closeLoadingAndShowSuccess = ({ toastId, message }) => {
  toast.dismiss(toastId);
  toast.success(message, { position: toast.POSITION.BOTTOM_RIGHT });
};

const closeLoadingAndShowError = ({ toastId, message }) => {
  toast.dismiss(toastId);
  toast.error(message, { position: toast.POSITION.BOTTOM_RIGHT });
};

const showToastForApiCall = async ({
  loadingMessage,
  succcessMessage,
  errorMessage,
  apiCall,
  afterSuccess = null,
  afterError = null,
}) => {
  const toastId = showLoadingToast({ message: loadingMessage });

  try {
    const data = await apiCall();
    if (data.error) {
      throw data?.error?.response?.data?.message;
    }

    closeLoadingAndShowSuccess({ toastId, message: succcessMessage });
    afterSuccess && afterSuccess(data);
  } catch (error) {
    closeLoadingAndShowError({
      toastId, message: `${errorMessage}${error ? ` ${error}` : null}`
    });
    afterError && afterError(error);
  }
};

export {
  showNotificationToast,
  showLoadingToast,
  showErrorToast,
  closeLoadingAndShowSuccess,
  closeLoadingAndShowError,
  showToastForApiCall,
};
