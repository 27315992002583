import Link from "next/link"
import { HIDE_TSF_BRANDING } from '/global.config'

const Footer = () => {
  return (
    <footer className="py-10 mt-auto">
      <div className="container mx-auto">
        <p className="copyright text-center text-secondary text-sm">
          {!!!HIDE_TSF_BRANDING && (
            <>
              Powered by <Link href='https://www.thestonedfrogs.io/' passHref><a target='_blank'>The Stoned Frogs</a></Link>
            </>
          )}
        </p>
      </div>
    </footer>
  )
}

export default Footer
