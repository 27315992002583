import Cookies from 'js-cookie'

class BrowserHelper {
  get name() { return 'browser' }
  get brand() { return 'browser' }
  get model() { return 'browser' }
  get osVersion() { return 'browser' }

  get imei() {
    let imei = Cookies.get('BROWSER_IMEI')
    if (imei) { return imei }

    imei = this.randomString
    for (var i = 0; i < 10; i++) { imei = `${imei}-${this.randomString}` }
    Cookies.set('BROWSER_IMEI', imei, { expires: null })
    return imei
  }

  get randomString() {
    return (Math.random() + 1).toString(36).substring(2)
  }
}

export default new BrowserHelper()