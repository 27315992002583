import Base from './Base'

class User extends Base {
  async info() {
    const data = await this.get('users')
    return data?.data
  }

  async update(params) {
    const data = await this.put('users', params)
    return data?.data
  }
}

export default new User()