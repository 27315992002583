import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
import LogoutIcon from "/assets/images/logout.svg";
import { useRouter } from "next/router";
import clsx from "clsx";

import moon from "/assets/images/moon.png";
import sun from "/assets/images/sun.png";

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useAuth } from "/contexts/auth";
import ClientConfig from "/client.config.json";
import {
  S3_FOLDER_PATH,
  PROJECT_NAME,
  EXTRA_MENU,
  COLORS,
  TWITTER_USERNAME,
  DISCORD_INVITATION_ID,
} from "/global.config";
import LoginButton from "/components/LoginButton";

import TwitterIcon from "/assets/images/twitter.svg";
import DiscordIcon from "/assets/images/discord.svg";

const HEADER_BUTTON_CSS =
  "bg-gradient-to-r from-purple-600 to-indigo-500 via-indigo-500 rounded-md shadow-sm py-2 px-4 mr-4 lg:mr-0 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-neutral-50 focus:ring-indigo-500";

const Header = () => {
  const router = useRouter();
  const { user, logout } = useAuth();
  const [userImage, setUserImage] = useState(user?.image_url);

  let MenuItems = [
    {
      name: "Calendar",
      href: "/calendar",
    },
    ClientConfig.enable_raffles && {
      name: "Raffles",
      href: "/raffles",
    },
    ClientConfig.enable_auctions && {
      name: "Auctions",
      href: "/auctions",
    },
    ClientConfig.enable_dao_voting && {
      name: "DAO Voting",
      href: "/proposals",
    },
    ClientConfig.enable_custom_swap && {
      name: "Swap",
      href: "/swap",
    },
    ClientConfig.enable_mini_games && {
      name: "Mini Games",
      href: "/mini-games",
    },
    ClientConfig.enable_staking && {
      name: "Staking",
      href: "/staking",
    },
  ];

  if (user) {
    MenuItems.push({ name: "Account", href: "/account" });
  }

  MenuItems = MenuItems.concat(EXTRA_MENU || []).filter((item) => !!item);

  const [isdarkMode, setDarkMode] = useState(
    localStorage.getItem("color-theme") === "dark" ||
      document.documentElement.classList.contains("dark")
      ? true
      : false
  );

  const toggleDarkMode = () => {
    if (isdarkMode) {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("color-theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      localStorage.setItem("color-theme", "dark");
    }
    setDarkMode(!isdarkMode);
  };

  const UserSigninOrProfile = () => {
    if (user) {
      return (
        <>
          <WalletMultiButton
            className={clsx("wallet-adapter-button", HEADER_BUTTON_CSS)}
          />

          <div
            className="items-center px-2 gap-2 cursor-pointer hidden lg:flex"
            onClick={() => router.push("/account/profile")}
          >
            <div className="mb-1">
              <Image
                src={userImage}
                width={24}
                height={24}
                alt="Profile"
              />
            </div>
            <div className="text-secondary">{user?.first_name}</div>
          </div>

          <div className="px-2 hidden lg:flex" onClick={logout}>
            <div className="cursor-pointer mt-1" onClick={() => logout()}>
              <LogoutIcon width={24} height={24} />
            </div>
          </div>
        </>
      );
    }

    return <LoginButton className="text-sm" />;
  };

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 shadow montserrat bg-primary text-secondary">
      <div className="flex flex-wrap justify-between items-center mx-auto">
        <div className="h-6 sm:h-9 pl-3 lg:pl-10 hidden sm:block">
          <Link passHref href="/">
            <a className="flex items-center">
              <Image
                src={`${S3_FOLDER_PATH}logo.png`}
                alt={PROJECT_NAME}
                width={90}
                height={90}
              />
            </a>
          </Link>
        </div>

        <div className="h-full pl-3 sm:hidden">
          <Link passHref href="/">
            <a className="flex items-center">
              <Image
                src={`${S3_FOLDER_PATH}logo.png`}
                alt={PROJECT_NAME}
                width={50}
                height={50}
              />
            </a>
          </Link>
        </div>

        <div className="flex lg:order-2 justify-center items-center lg:py-0 py-2 pr-3 lg:pr-10">
          {COLORS.dark && (
            <button
              type="button"
              className={`text-gray-600 dark:text-gray-400 bg-gray-800 dark:bg-gray-200 border-4 border-gray-600 dark:border-gray-400 rounded-full text-sm w-10 h-10 p-auto pt-1.5 mr-4`}
              onClick={toggleDarkMode}
            >
              {isdarkMode ? (
                <Image src={sun} alt="sun" width={20} height={20} />
              ) : (
                <Image src={moon} alt="moon" width={20} height={20} />
              )}
            </button>
          )}

          <div className="social-icons">
            {TWITTER_USERNAME && (
              <div>
                <a href={`https://twitter.com/${TWITTER_USERNAME}`} target="_blank" rel="noreferrer">
                  <TwitterIcon className='social-icon' width={20} height={20} />
                </a>
              </div>
            )}

            {DISCORD_INVITATION_ID && (
              <div>
                <a href={`https://discord.gg/${DISCORD_INVITATION_ID}`} target="_blank" rel="noreferrer">
                  <DiscordIcon className='social-icon' width={20} height={20} />
                </a>
              </div>
            )}
          </div>

          <UserSigninOrProfile />

          <button
            onClick={() => {
              document.getElementById("nav-content").classList.toggle("hidden");
            }}
            type="button"
            className="inline-flex items-center p-2 text-sm rounded-lg lg:hidden focus:outline-none focus:ring-2 bg-secondary"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6 burger-menu"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div
          className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
          id="nav-content"
        >
          <div className="flex flex-col mt-12 gap-2 lg:flex-row lg:gap-4 lg:mt-0 lg:text-sm lg:font-medium">
            {MenuItems.map(({ name, href, target }) => (
              <Link key={name} href={href} passHref>
                <a target={target || "_self"}>
                  <div
                    className={clsx(
                      "text-base cursor-pointer font-semibold py-2 lg:py-5",
                      router.pathname.includes(href) &&
                      "border-b-4 border-secondary"
                    )}
                  >
                    {name}
                  </div>
                </a>
              </Link>
            ))}

            <a onClick={logout} className="lg:hidden">
              <div className="text-base cursor-pointer font-semibold py-2 lg:py-5">
                Logout
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
